<template>
    <Dialog maxWidth="700px" :title="$t('user.create.title')" :open="open" :progress="progress" :saveProgress="saveProgress" @cancel="$emit('cancel')" @save="save()">
        <v-select
            class="mb-3"
            v-if="roles.length > 0"
            v-model="roleSelect"
            :items="roles"
            item-text="name"
            item-value="_id"
            :label="$t('user.form.role')"
            :disabled="saveProgress"
            hide-details="true"
            persistent-hint
            outlined
            prepend-inner-icon="mdi-account-group"
        />
        
        <v-row>
            <v-col class="cal-12 col-sm-8 pr-sm-1 pb-0">
                <v-text-field
                    :label="$t('user.form.name')"
                    v-model="form.name"
                    :error-messages="errors.name"
                    :hide-details="!errors.name"
                    :disabled="saveProgress"
                    @change="validate('name')"
                    outlined
                    prepend-inner-icon="mdi-account"
                />
            </v-col>

            <v-col class="col-12 col-sm-4 pl-sm-1 pb-0">
                <v-text-field
                    :label="$t('user.form.title')"
                    v-model="form.title"
                    :error-messages="errors.title"
                    :hide-details="!errors.title"
                    :disabled="saveProgress"
                    @change="validate('title')"
                    outlined
                    prepend-inner-icon="mdi-school"
                />
            </v-col>
        </v-row>

        <v-row class="mt-3">
            <v-col class="col-12 col-sm-6 pr-sm-1 pt-0 pb-0 pt-sm-0 pb-sm-3">
                <v-text-field
                    class="mt-3"
                    :label="$t('user.form.email')"
                    v-model="form.email"
                    :error-messages="errors.email"
                    :hide-details="!errors.email"
                    :disabled="saveProgress"
                    @change="validate('email')"
                    outlined
                    prepend-inner-icon="mdi-email"
                />
            </v-col>

            <v-col class="col-12 col-sm-6 pl-sm-1 pt-0 pb-3 pt-sm-0 pb-sm-3">
                <v-text-field
                    class="mt-3"
                    :label="$t('user.form.phone')"
                    :error-messages="errors.phone"
                    :hide-details="!errors.phone"
                    :disabled="saveProgress"
                    v-model="form.phone"
                    @change="validate('phone')"
                    outlined
                    prepend-inner-icon="mdi-phone"
                />
            </v-col>
        </v-row>

        <v-text-field
            class="mt-3"
            :label="$t('user.form.address')"
            :error-messages="errors.address"
            :hide-details="!errors.address"
            :disabled="saveProgress"
            v-model="form.address"
            @change="validate('address')"
            outlined
            prepend-inner-icon="mdi-map-marker"
        />

        <v-text-field
            class="mt-3"
            v-model="form.password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            name="input-10-1"
            :label="$t('user.form.password.password')"
            :error-messages="errors.password"
            :hide-details="!errors.password"
            :disabled="saveProgress"
            @change="validate('password')"
            @click:append="show = !show"
            outlined
            prepend-inner-icon="mdi-lock"
        />
        <v-alert
            class="mt-3"
            v-if="noDefaultRole || (this.user.hasPerm('role') && roles.length == 0)"
            dense
            outlined
            type="error"
        >
            {{ $t('user.create.no_default_role') }}
        </v-alert>
        <v-alert
            class="mt-3"
            v-if="emailExists"
            dense
            outlined
            type="error"
        >
            {{ $t('user.create.email_exists') }}
        </v-alert>
    </Dialog>
</template>

<script>
import Dialog from "../components/Dialog.vue";
import Settings from "../Settings.vue";
import validator from "../../plugins/validator";
import * as userValidation from "../../api/user/validation";

export default {
    components: {
        Dialog,
        Settings
    },

    props: {
        open: {
            type: Boolean,
            default: false
        },

        title: {
            type: String,
            default: null
        },

        entity: {
            type: String,
            default: null
        }
    },

    data: () => ({
        form: {
            name: null,
            title: null,
            email: null,
            phone: null,
            address: null,
            password: null
        },

        errors: {
            name: null,
            title: null,
            email: null,
            phone: null,
            address: null,
            password: null,
        },

        noDefaultRole: false,
        emailExists: false,
        show: true,
        saveProgress: false,
        progress: true,

        roles: [],
        roleSelect: null
    }),

    async beforeMount() {
        await this.load();
    },

    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        async load() {
            if(this.user.hasPerm("role")){
                const res = await this.$store.dispatch("api/role/all", { entity: (this.entity) ? this.entity : undefined });
                if (res.status == 200) {
                    this.roles = res.data;
                    let defaultRole = res.data.find(x => x.default == true);
                    if (defaultRole) this.roleSelect = defaultRole._id;
                }
            }
            
            this.progress = false;
        },

        validate(check) {
            if(check) this.errors[check] = null;
            else Object.assign(this.$data.errors, this.$options.data().errors);

            return validator(
                this.form,
                userValidation.create,
                function(key, type, limit) {
                    if(check && key != check) return;
                    let text = this.$t(`form_errors.${key}.${type}`);
                    this.errors[key] = text;
                }.bind(this)
            );
        },

        async save() {
            if (!this.validate()) return;
            if(this.entity) this.form.entity = this.entity;
            if(this.user.hasPerm("role") && this.roleSelect) this.form.role = this.roleSelect;

            this.emailExists = false;

            this.saveProgress = true;
            let res = await this.$store.dispatch("api/user/create", this.form);
            this.saveProgress = false;

            if (res.status == 200) {
                this.$emit("save");
                this.$emit("cancel");
            } else if (res.data.error.code == "DefaultRoleNotSpecified" ) { 
                this.noDefaultRole = true; //informacja o braku roli domyslnej 
            } else if (res.data.error.code == "UserExists" ) { 
                this.emailExists = true; //informacja istnieniu konta dla podanego email
            }
        }
    }
};
</script>
