<template>
    <v-card class="d-flex align-start flex-column rounded-0" elevation="1" :height="height">
        <v-card-title class="text-h5" v-if="title" style="width: 100%">
            <v-spacer>
                <div class="d-flex align-center justify-space-between">
                    <div>
                        <v-icon left size="30" v-if="icon">{{ icon }}</v-icon> {{ title }}
                    </div>
                    
                    <div>
                        <slot name="headerActions"></slot>
                    </div>
                </div>
            </v-spacer>
        </v-card-title>

        <v-card-text v-if="!loaded" class="d-flex justify-center">
            <v-progress-circular
                class="mt-6 mb-6"
                :size="50"
                color="primary"
                indeterminate
            />
        </v-card-text>

        <template v-if="loaded">
            <v-card-text>
                <div class="mt-1 grey--text text--darken-2 subtitle-1">
                    <slot></slot>
                </div>
            </v-card-text>

            <v-card-actions class="justify-end align-center pr-4 pb-4 pl-4 pt-0 mt-auto" style="width: 100%" v-if="hasSlotActions || hasCancel || hasSave">
                <div v-if="updateTime" class="actions-info d-flex justify-start align-center pl-0 pt-0 pb-0 mt-0 mb-0" style="width: 100%">
                    <span class="d-none d-md-flex font-weight-medium mr-1">
                        {{ $t('last_update') }}
                    </span>
                    <span class="d-none d-md-flex font-weight-regular">
                        {{ updateTimeToString() }}
                    </span>
                    <span class="d-flex d-md-none font-weight-medium">
                        <v-icon left>mdi-update</v-icon>
                    </span>
                    <span class="d-flex d-md-none font-weight-regular">
                        {{ updateTimeToString(true) }}
                    </span>
                </div>
                
                <slot name="actions"></slot>

                <v-btn
                    class="pr-3"
                    v-if="hasCancel"
                    :disabled="saveProgress"
                    @click="$emit('cancel')"
                    color="red darken-2 white--text"
                >
                    <v-icon left>mdi-close</v-icon> {{ $t("cancel") }}
                </v-btn>

                <v-btn 
                    class="pr-3"
                    v-if="hasSave" 
                    :loading="saveProgress" 
                    :disabled="saveProgress"
                    @click="$emit('save')" 
                    color="primary"
                >
                    <v-icon left>mdi-content-save-outline</v-icon> {{ $t("save") }}
                </v-btn>
            </v-card-actions>
        </template>
    </v-card>
</template>

<style lang="scss" scoped>
    .actions-info{
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.375rem;
        letter-spacing: 0.0071428571em;
        color: rgba(0, 0, 0, 0.6);
    }
</style>

<script>
export default {
    props: {
        title: {
            type: String,
            default: null
        },

        icon: {
            type: String,
            default: null
        },

        height: {
            type: String,
            default: null,
        },

        loaded:{
            type: Boolean,
            default: true
        },

        saveProgress: {
            type: Boolean,
            default: null
        },

        updateTime: {
            type: Date,
            default: null
        }
    },
    computed: {
        hasSlotBtnTop() {
            return this.$slots.btnTop;
        },

        hasSlotActions() {
            return this.$slots.actions;
        },

        hasSave() {
            return this.$listeners && this.$listeners.save;
        },

        hasCancel() {
            return this.$listeners && this.$listeners.cancel;
        }
    },

    methods: {
        updateTimeToString(short = false) {

            let diff = (Date.now() - (this.updateTime.getTime() - 1000 * this.updateTime.getSeconds() - this.updateTime.getMilliseconds()))/1000;

            let counter = "";

            if (diff < 60) { counter = " (" + this.$t('time_units.less_than') + " " + this.$t('time_units.mins.single') + " " + this.$t('time_units.ago') + ")"; }
            else if (diff >= 60 && diff < 2*60) { counter = " (" + this.$t('time_units.mins.single') + " " + this.$t('time_units.ago') + ")"; }
            else if (diff >= 2*60 && diff < 60*60) { counter = " (" + parseInt(diff/60) + " " + this.$t('time_units.mins.plural') + " " + this.$t('time_units.ago') + ")"; }
            else if (diff >= 60*60 && diff < 2*60*60) { counter = " (" + this.$t('time_units.hours.single') + " " + this.$t('time_units.ago') + ")"; }
            else if (diff >= 2*60*60 && diff < 24*60*60) { counter = " (" + parseInt(diff/(60*60)) + " " + this.$t('time_units.hours.plural') + " " + this.$t('time_units.ago') + ")"; }

            if (short) {
                return counter.length == 0 
                    ? this.updateTime.toLocaleDateString([], {day: "2-digit", month: "2-digit", year: "numeric",}) 
                    : counter.substring(2, counter.length - 1);
            } else {
                return this.updateTime.toLocaleDateString([], {day: "2-digit", month: "2-digit", year: "numeric",}) + " " + 
                this.updateTime.toLocaleTimeString([], {hour: "2-digit", minute: "2-digit",}) + counter;
            }
        }
    }
};
</script>
