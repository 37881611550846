import Joi from "joi";

export const auth = Joi.object({
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .required(),
    password: Joi.string()
        .min(4)
        .max(255)
        .required()
});

export const create = Joi.object({
    entity: Joi.string()
        .regex(/^[0-9a-fA-F]{24}$/)
        .optional(),
    role: Joi.string()
        .regex(/^[0-9a-fA-F]{24}$/)
        .optional(),
    name: Joi.string()
        .min(4)
        .max(255)
        .trim()
        .required(),
    title: Joi.string()
        .min(1)
        .max(255)
        .trim()
        .optional()
        .allow(null, ""),
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .trim()
        .optional(),
    phone: Joi.string()
        .min(9)
        .max(14)
        .pattern(/^[+0-9]+$/)
        .optional(),
    address: Joi.string()
        .min(3)
        .max(255)
        .optional()
        .allow(null, ""),
    password: Joi.string()
        .min(6)
        .max(255)
        .required()
});

export const update = Joi.object({
    id: Joi.string()
        .regex(/^[0-9a-fA-F]{24}$/)
        .optional(),
    name: Joi.string()
        .min(4)
        .max(255)
        .trim()
        .required(),
    title: Joi.string()
        .min(1)
        .max(255)
        .trim()
        .optional()
        .allow(null, ""),
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .trim()
        .required(),
    phone: Joi.string()
        .min(9)
        .max(14)
        .pattern(/^[+0-9]+$/)
        .optional()
        .allow(null, ""),
    address: Joi.string()
        .min(3)
        .max(255)
        .allow(null)
        .optional()
        .allow(null, ""),
    password: Joi.string()
        .min(4)
        .max(255)
        .optional()
        .allow(null, ""),
    role: Joi.string()
        .regex(/^[0-9a-fA-F]{24}$/)
        .optional(),
    enabled: Joi.boolean()
        .optional()
});

export const update_password = Joi.object({
    id: Joi.string()
        .regex(/^[0-9a-fA-F]{24}$/)
        .optional(),
    old_password: Joi.string()
        .min(4)
        .max(255)
        .required(),
    password: Joi.string()
        .min(4)
        .max(255)
        .required(),
    repeat: Joi.string()
        .min(4)
        .max(255)
        .required()
});

export const change_password = Joi.object({
    id: Joi.string()
        .regex(/^[0-9a-fA-F]{24}$/)
        .optional(),
    password: Joi.string()
        .min(4)
        .max(255)
        .required()
});