<template>
    <Page icon="mdi-cog" :title="$t('routes.settings')" :progress="progress">
        <v-row>
            <v-col class="col-12 col-md-6 mt-0">
                <Card :title="$t('user.update.title')" :updateTime="updateTime" :saveProgress="saveProgress" @save="save()">
                    <v-row>
                        <v-col class="col-12 col-md-8 pt-0">
                            <v-text-field
                                :label="$t('user.form.name')"
                                :error-messages="errors.name"
                                :hide-details="!errors.name"
                                :disabled="saveProgress"
                                v-model="form.name"
                                @change="validate('name')"
                                outlined
                                prepend-inner-icon="mdi-account"
                            />
                        </v-col>

                        <v-col class="col-12 col-md-4 pt-0">
                            <v-text-field
                                :label="$t('user.form.title')"
                                :error-messages="errors.title"
                                :hide-details="!errors.title"
                                :disabled="saveProgress"
                                v-model="form.title"
                                @change="validate('title')"
                                outlined
                                prepend-inner-icon="mdi-school"
                            />
                        </v-col>
                    </v-row>

                    <v-text-field
                        class="mt-3"
                        :label="$t('user.form.email')"
                        v-model="form.email"
                        :error-messages="errors.email"
                        :hide-details="!errors.email"
                        disabled
                        outlined
                        prepend-inner-icon="mdi-email"
                    />

                    <v-text-field
                        class="mt-3"
                        :label="$t('user.form.phone')"
                        :error-messages="errors.phone"
                        :hide-details="!errors.phone"
                        :disabled="saveProgress"
                        v-model="form.phone"
                        @change="validate('phone')"
                        outlined
                        prepend-inner-icon="mdi-phone"
                    />

                    <v-text-field
                        class="mt-3"
                        :label="$t('user.form.address')"
                        :error-messages="errors.address"
                        :hide-details="!errors.address"
                        :disabled="saveProgress"
                        v-model="form.address"
                        @change="validate('address')"
                        outlined
                        prepend-inner-icon="mdi-map-marker"
                    />
                </Card>
            </v-col>

            <v-col class="col-12 col-md-6 mt-0 pt-0 pt-md-3">
                <Card :title="$t('user.update.password_title')" :updateTime="updateTimePassword" :saveProgress="saveProgressPassword" @save="savePassword()" height="100%">
                    <v-text-field
                        v-model="form_password.old_password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        :label="$t('user.form.password.actual')"
                        :error-messages="errorsPassword.old_password"
                        :hide-details="!errorsPassword.old_password"
                        :disabled="saveProgressPassword"
                        @change="validatePassword('old_password')"
                        @click:append="show1 = !show1"
                        outlined
                        prepend-inner-icon="mdi-lock"
                    />

                    <v-text-field
                        class="mt-6"
                        v-model="form_password.password"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show2 ? 'text' : 'password'"
                        name="input-10-1"
                        :label="$t('user.form.password.new')"
                        :error-messages="errorsPassword.password"
                        :hide-details="!errorsPassword.password"
                        :disabled="saveProgressPassword"
                        @change="validatePassword('password')"
                        @click:append="show2 = !show2"
                        outlined
                        prepend-inner-icon="mdi-lock-plus-outline"
                    />

                    <v-text-field
                        class="mt-3"
                        v-model="form_password.repeat"
                        :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show3 ? 'text' : 'password'"
                        name="input-10-1"
                        :label="$t('user.form.password.repeat')"
                        :error-messages="errorsPassword.repeat"
                        :hide-details="!errorsPassword.repeat"
                        :disabled="saveProgressPassword"
                        @change="validatePassword('repeat')"
                        @click:append="show3 = !show3"
                        outlined
                        prepend-inner-icon="mdi-lock-plus-outline"
                    />
                </Card>
            </v-col>
        </v-row>
    </Page>
</template>

<script>
import Page from "./components/Page";
import Card from "./components/Card";
import validator from "../plugins/validator";
import * as userValidation from "../api/user/validation";

export default {
    components: {
        Page,
        Card
    },

    async mounted() {
        this.load();
    },

    data: () => ({
        progress: true,
        user: null,

        show1: false,
        show2: false,
        show3: false,

        form: {
            name: null,
            title: null,
            email: null,
            phone: null,
            address: null
        },

        errors: {
            name: null,
            title: null,
            email: null,
            phone: null,
            address: null
        },

        form_password: {
            old_password: null,
            password: null,
            repeat: null
        },

        errorsPassword: {
            old_password: null,
            password: null,
            repeat: null
        },

        saveProgress: false,
        saveProgressPassword: false,
        updateTime: null,
        updateTimePassword: null

    }),

    methods: {
        async load() {
            const res = await this.$store.dispatch("api/user/current");

            if (res.status == 200) {
                this.progress = false;
                this.user = res.data;
                this.form.name = this.user.name;
                this.form.title = this.user.title;
                this.form.email = this.user.email;
                this.form.phone = this.user.phone;
                this.form.address = this.user.address;
                this.form_password.password = null;
                this.form_password.old_password = null;
                this.form_password.repeat = null;
                this.updateTime = this.user.updateTime ? new Date(this.user.updateTime) : null;
                this.updateTimePassword = this.user.updateTimePassword ? new Date(this.user.updateTimePassword) : null;
            }
        },

        validate(check) {
            if(check) this.errors[check] = null;
            else Object.assign(this.$data.errors, this.$options.data().errors);

            return validator(
                this.form,
                userValidation.update,
                function(key, type, limit) {
                    if(check && key != check) return;
                    let text = this.$t(`form_errors.${key}.${type}`);
                    this.errors[key] = text;
                }.bind(this)
            );
        },

        validatePassword(check) {
            if(check) this.errorsPassword[check] = null;
            else Object.assign(this.$data.errorsPassword, this.$options.data().errorsPassword);

            if (this.form_password.password != this.form_password.repeat) return (this.errorsPassword.repeat = this.$t(`form_errors.password.no_equal`));

            return validator(
                this.form_password,
                userValidation.update_password,
                function(key, type, limit) {
                    if(check && key != check) return;

                    if (key == "old_password") {
                        key = "password";
                        let text = this.$t(`form_errors.${key}.${type}`);
                        this.errorsPassword["old_password"] = text;
                    } else if (key == "repeat") {
                        key = "password";
                        let text = this.$t(`form_errors.${key}.${type}`);
                        this.errorsPassword["repeat"] = text;
                    } else {
                        let text = this.$t(`form_errors.${key}.${type}`);
                        this.errorsPassword[key] = text;
                    }
                }.bind(this)
            );
        },

        async save() {
            if(!this.validate()) return;

            this.saveProgress = true;
            await this.$store.dispatch("api/user/update", this.form);
            await this.load()
            this.saveProgress = false;
        },

        async savePassword() {
            if(!this.validatePassword()) return;

            this.saveProgressPassword = true;
            let res = await this.$store.dispatch("api/user/update_password", this.form_password);

            if (res.status != 200) {
                if(res.data.error.code == "InvalidPassword") this.errorsPassword.old_password = this.$t("form_errors.password.wrong_old_password");
            }else await this.load()

            this.saveProgressPassword = false;
        }
    }
};
</script>
