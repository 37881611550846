<template>
    <Page icon="mdi-account-multiple-check-outline" :title="$t('routes.users')" :progress="progress">
        <ActionBar @filter="s => load(s)" :searchProgress="searchProgress">
            <v-btn 
                @click="dialog = true"
                class="primary"
            >
                <v-icon left>mdi-plus</v-icon> {{ $t("user.create.title") }}
            </v-btn>
        </ActionBar>

        <v-simple-table class="mt-3">
            <thead>
                <tr>
                    <th class="w-avatar"></th>
                    <th class="primary--text">
                        <SortHeader 
                            :text="$t('user.list.name')"
                            v-model="form.sort"
                            @change="load(form.filter)"
                            field="name"
                            type="alphanum"
                        />
                    </th>
                    <th class="primary--text">
                        <SortHeader 
                            :text="$t('user.list.phone')"
                            v-model="form.sort"
                            @change="load(form.filter)"
                            field="phone"
                            type="numeric"
                        />
                    </th>
                    <th class="primary--text">
                        <SortHeader 
                            :text="$t('user.list.role')"
                            v-model="form.sort"
                            @change="load(form.filter)"
                            field="role.name"
                            type="alphanum"
                        />
                    </th>
                    <th class="primary--text w-action">
                        {{ $t("user.list.edit") }}
                    </th>
                </tr>
            </thead>
            <tbody style="white-space: nowrap;">
                <tr v-for="(u, index) in users" :key="index">
                    <td class="pt-3 pb-3 w-avatar">
                        <v-avatar size="40">
                            <v-gravatar :email="u.email" protocol="https" default-img="mm" />
                        </v-avatar>
                    </td>

                    <td>
                        <tr>
                            <span class="font-weight-bold">{{ u.title }} {{ u.name }}</span>
                        </tr>
                        <tr>
                            <span class="grey--text text--darken-2">
                                <v-icon size="16">mdi-at</v-icon> {{ u.email }}
                            </span>
                        </tr>
                    </td>

                    <td>
                        <v-icon class="mr-1" size="16">mdi-phone</v-icon> {{ (u.phone) ? u.phone : $t("empty") }}
                    </td>

                    <td>{{ u.role.name }}</td>

                    <td class="w-action">
                        <v-btn
                            fab
                            color="primary"
                            x-small
                            dark
                            depressed
                            :to="{ name: 'user', params: { id: u._id, routefrom: 'users' } }"
                            ><v-icon>mdi-cog</v-icon></v-btn
                        >
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

        <v-pagination
            class="mt-3"
            @input="load(form.filter)"
            v-if="pagination.total > 1"
            v-model="form.page"
            :length="pagination.total"
            :total-visible="7"
        />

        <UserCreate 
            :entity="selectedEntity()" 
            v-if="dialog"
            :open="dialog"
            @cancel="dialog = false" 
            @save="load(form.filter)" 
        />
    </Page>
</template>

<script>
import Page from "./components/Page.vue";
import ActionBar from "./components/ActionBar.vue";
import SortHeader from "./components/SortHeader.vue";
import UserCreate from "../views/dialogs/UserCreate.vue";

export default {
    components: {
        Page,
        ActionBar,
        SortHeader,
        UserCreate
    },

    async mounted() {
        await this.load();
    },

    data: () => ({
        progress: true,
        searchProgress: false,
        users: null,
        dialog: false,

        pagination: {
            total: 0,
            filter: null
        },

        form: {
            page: 1,
            limit: null,
            filter: null,
            sort: "name ",
        }
    }),

    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        async load(filter) {
            if (filter) this.form.filter = filter;
            else this.form.filter = null;

            if(this.pagination.filter != filter) this.form.page = 1;
            this.pagination.filter = filter;

            this.searchProgress = !this.progress;

            this.selectedEntity();

            const res = await this.$store.dispatch("api/user/all", this.form);
            
            if (res.task.count == 0) {
                this.progress = false;
                this.searchProgress = false;

                if(res.status == 200) {
                    this.users = res.data;
                    this.pagination.total = res.pages;
                }
            }
        },

        selectedEntity(){
            let entity = this.$store.state.api.user.selectedEntity;
            if(!entity) return null;
            this.form.entity = entity._id;
            return entity._id;
        }
    }

};
</script>
